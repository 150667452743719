import type { NextPageWithAuthOptions, Session } from '@side/next-auth';
import { Suspense } from 'react';
import dynamic from 'next/dynamic';
import { ApolloProvider } from '@apollo/client';
import { client } from 'src/modules/graphql';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import { ThemeProvider } from 'src/components/ThemeProvider';
import CssBaseline from '@mui/material/CssBaseline';
import { CacheProvider, type EmotionCache } from '@emotion/react';
import { ToastProvider } from '@side/pantry';
import { SessionProvider } from '@side/next-auth/react';
import { PantryProvider } from 'src/modules/pantry/PantryProvider';
import { SetupMaze } from 'src/components/Initialization/SetupMaze';
import { AppRefreshModal } from 'src/components/AppRefreshModal';
import { initDatadog } from 'src/modules/datadog';
import { createEmotionCache } from '../src/theme/createEmotionCache';
import { LaunchDarkly } from '../src/modules/launchdarkly/provider';
import { inter } from '../src/theme/interFont';

const clientSideEmotionCache = createEmotionCache();

const Initialization = dynamic(
  () =>
    import('src/components/Initialization').then(
      (exported) => exported.Initialization,
    ),
  { ssr: false },
);

initDatadog();

export interface SideAppProps
  extends AppProps<{ session: Session | null | undefined }> {
  emotionCache?: EmotionCache;
  Component: NextPageWithAuthOptions;
}

// eslint-disable-next-line import/no-default-export
export default function SideApp(props: SideAppProps) {
  const { Component, emotionCache = clientSideEmotionCache, pageProps } = props;
  const { session, ...additionalPageProps } = pageProps;

  return (
    <>
      {/**
       * Setup font in head as described in next docs:
       * https://nextjs.org/docs/basic-features/font-optimization#apply-the-font-in-head
       */}
      {/* eslint-disable-next-line react/no-unknown-property */}
      <style jsx global>{`
        :root {
          font-family: ${inter.style.fontFamily};
          --side-font-family:
            ${inter.style.fontFamily}, open-sans, 'Helvetica Neue', Helvetica,
            Arial, sans-serif;
        }
        @supports (font-variation-settings: normal) {
          :root {
            --side-font-family:
              ${inter.style.fontFamily}, open-sans, 'Helvetica Neue', Helvetica,
              Arial, sans-serif;
          }
        }
      `}</style>
      <SetupMaze />
      <ApolloProvider client={client}>
        <CacheProvider value={emotionCache}>
          <Head>
            <title>Side</title>
            <meta
              name="viewport"
              content="minimum-scale=1, initial-scale=1, width=device-width"
            />
          </Head>
          <SessionProvider
            session={session}
            basePath="/identity/api/auth"
            pageOptions={Component.auth}
          >
            <LaunchDarkly>
              <PantryProvider>
                <ThemeProvider>
                  <ToastProvider />
                  <Suspense fallback={null}>
                    <Initialization />
                  </Suspense>
                  <CssBaseline />
                  <Component {...additionalPageProps} />
                  <AppRefreshModal />
                </ThemeProvider>
              </PantryProvider>
            </LaunchDarkly>
          </SessionProvider>
        </CacheProvider>
      </ApolloProvider>
    </>
  );
}
